import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import product_back from "../images/product_back.png";
import Roobot2 from "../images/RooBot2.png";
import demovideo2 from "../video/RoobricksIntro.mp4"
import keyboard from "../images/Keyboard.png";
import {Helmet} from "react-helmet";
import SEO from "./seo";

export default function demo2() {
    return <div>
        <div className="container-fluid p-0">
            <div className="row m-0">
                <div className="col-md-12 p-0">
                    <div style={{ backgroundImage: `url(${product_back})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPositionX: 'center' }}>
                        <div style={{ minHeight: 150 }}>
                            <Header/>
                        </div>
                        <div style={{ height: 120 }}>
                        </div>
                        <div>
                            <div className="home-hero-div">
                                <div className="home-hero-titlelight">Demo (Private)</div>
                            </div>
                        </div>
                        <div style={{ height: 240 }}></div>
                    </div>
                </div>
            </div>
        </div>
        <div style={{ height: 50 }}></div>
        <div className="container-fluid">
            <div className="row m-5">
                <div className="col-md-12">
                </div>
            </div>
            <div className="row m-0">
                <div className="col-md-12">
                    <div style={{ width: '80%', margin: 'auto' }} >
                        <video style={{ width: '100%' }} controls>
                            <source src={demovideo2} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
        </div>

        <div style={{ height: 100 }}></div>

        <div className="roobot-2">
            <img src={Roobot2}/>
        </div>
        <br clear="all"/>

        <Footer />

    </div>
}